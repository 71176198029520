/* product list */
.products-list .product{
    max-width: 380px;
    margin: auto;
}
.products-list .product .img img{
    height: 50vh;
    min-height: 200px;
    max-height: 260px;
}
.products-list .product .content{
    background-color: rgba(var(--yellow-rgb) / 6%);
}
.products-list .product .content .title {
    font-size: 24px;
    letter-spacing: 1px;
    color: rgba(var(--yellow-rgb) / 100%);
}
.products-list .product .content .title p{
    margin-bottom: 5px;
    color: rgba(var(--black-rgb) / 70%);
}
.products-list .product .content .desc{
    color: rgba(var(--black-rgb) / 65%);
    font-size: 14px;
}
.products-list .view-more{
    background-color: rgba(var(--white-rgb) / 80%);
    cursor: pointer;
    opacity: 0;
    transition: opacity .4s ease;
}
.products-list .view-more:hover{ opacity: 1; }
.products-list .view-more .icon{ width: 45px; height: 45px; background-color: rgba(var(--yellow-rgb) / 90%); }
.products-list .view-more svg{ width: 15px; fill: rgba(var(--white-rgb) / 100%); }

/* product details */

.product-details{
    background-color:rgba(var(--white-rgb) / 100%); ;
 }
.product-details-list h5{
    color: rgba(var(--black-rgb) / 90%);
}
.product-details-list ul{
    list-style: none;
    margin:0;
    padding:0;
}
.product-details-list svg{
    fill:rgba(var(--yellow-rgb) / 100%);
}
.product-details-list ul li {
    line-height: 40px;
}
.product-details-list .carousel {
    position: relative;
}
.product-details-list .carousel-indicators {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    list-style: none;
    margin:0px; /* Adjust margin as needed */
}
.product-details-list .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(var(--black-rgb) / 30%); /* Indicator color */
    cursor: pointer;
    margin:10px;
}
.product-details-list .carousel-indicators .active {
    background-color:rgba(var(--yellow-rgb) / 100%); /* Active indicator color */
}
/* Styling for the carousel arrow icons */
.product-details-list .carousel-control-prev-icon,
.product-details-list .carousel-control-next-icon {
  background-color:rgba(var(--yellow-rgb) / 100%); /* Set the color of the arrow icons */
  border:5px solid rgba(var(--yellow-rgb) / 100%);
}
/* Styling for the carousel arrow icons */
.product-details-list .carousel-control-prev-icon,
.product-details-list .carousel-control-next-icon {
  width: 25px; /* Set the width of the arrow icons */
  height: 25px; /* Set the height of the arrow icons */
  background-color: rgba(var(--yellow-rgb) /100%); /* Set the color of the arrow icons */
  border-radius:50px;
}
.product-details-list .carousel-control-next, .product-details-list .carousel-control-prev{
    opacity: 1;
}
.product-details-list .view-all a, .product-details-list .view-all button{
    padding: 12px 28px;
    color: rgba(var(--white-rgb) / 100%);
    background-color:rgba(var(--yellow-rgb) / 100%);
    letter-spacing: 1px;
}
.product-details-list .view-all button:nth-child(2){
    background-color:rgba(var(--black-rgb) / 100%);
}
.product-details-list .carousel-item img{
    max-height: 400px;
    margin: auto;
    max-width: 100%;
    width: auto !important;
}