.loading_effect {
  -webkit-animation: fadeOut .3s;
  animation: fadeOut .3s;
  animation-fill-mode: forwards;

}

@-webkit-keyframes fadeOut {
    0% { opacity: 1; display: block; }
    99% { opacity: 0.01; }
    100% { opacity: 0; display: none; z-index: -999999;}
}  
@-moz-keyframes fadeOut {
  0% { opacity: 1; display: block; }
  99% { opacity: 0.01; }
  100% { opacity: 0; z-index: -999999; }
}
@keyframes fadeOut {
    0% { opacity: 1; display: block; }
    99% { opacity: 0.01; }
    100% { opacity: 0; display: none; z-index: -999999;}
}