.pageBannerCarousel .backgroundSymbol svg{ left: -10%; bottom: -20%; opacity: 0.25; }
.pageBannerCarousel .backgroundSymbol svg path:last-child{ fill: rgba(var(--black-rgb) /100%); mix-blend-mode: difference; }
.pageBannerCarousel .product-image{ aspect-ratio: 1/1; width: 100%; max-width: 500px; }
.pageBannerCarousel .product-image img{ height: 100%; width: auto; }

.pageBannerCarousel .content{ font-size: 10px; }
.pageBannerCarousel .content .title{ font-size: 4.4em; font-weight: 800; line-height: 1; margin-bottom: 2px; color: rgba(var(--white-rgb) / 100%); text-shadow: 1px 1px 3px rgba(var(--black-rgb) / 50%); }
.pageBannerCarousel .content .tagline{ color: rgba(var(--white-rgb) / 95%); font-size: 1.6em; text-shadow: .5px .5px 2px rgba(var(--black-rgb) / 40%); margin-bottom: 0.5em; }
.pageBannerCarousel .content .desc{ color: rgba(var(--white-rgb) / 90%); font-size: 1.4em; line-height: 1.4; text-shadow: .5px .5px 2px rgba(var(--black-rgb) / 30%); }
.pageBannerCarousel .content .btn{ background-color: rgba(var(--black-rgb) / 100%); color: rgba(var(--white-rgb) / 100%); padding: 0.8em 2.4em; line-height: 1; text-transform: uppercase; letter-spacing: 1px; font-size: 1.2em; background-blend-mode: difference; }

.pageBanner{ font-size: 10px; height: 50vh; min-height: 350px; }
.pageBanner .content{ padding: 5em 0em; max-width: 600px; margin: auto; }
.pageBanner .content .title{ font-size: 4.4em; font-weight: 800; line-height: 1; margin-bottom: 2px; color: rgba(var(--white-rgb) / 100%); text-shadow: 1px 1px 3px rgba(var(--black-rgb) / 50%); }
.pageBanner .content .tagline{ color: rgba(var(--white-rgb) / 95%); font-size: 1.6em; text-shadow: .5px .5px 2px rgba(var(--black-rgb) / 40%); margin-bottom: 0.5em; }
.pageBanner .content .desc{ color: rgba(var(--white-rgb) / 90%); font-size: 1.4em; line-height: 1.4; text-shadow: .5px .5px 2px rgba(var(--black-rgb) / 30%); }

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
    .pageBannerCarousel .content{ font-size: 11px; }
    .pageBanner{ font-size: 11px; }
}
@media (min-width: 1200px) {
    .pageBannerCarousel .content{ font-size: 13px; }
}
@media (min-width: 1400px) {
    .pageBannerCarousel .content{ font-size: 14px; }
}