section.section.home-section-blank-bg{background-attachment: fixed; background-position: center center; background-size: cover; height: 55vh; min-height: 250px; max-height: 600px; }

section.section.home-section-about .section-body{ max-width: 1000px; margin: auto; }

section.section.home-section-usps .home-section-usp-item{ padding: 30px 15px; border-radius: 4px; box-shadow: 2px 2px 8px rgba(var(--black-rgb) / 50%); background-color: rgba(var(--white-rgb) / 100%); max-width: 360px; margin: auto; }
section.section.home-section-usps .home-section-usp-item .icon img{ width: 80%; min-width: 90px; max-width: 120px; }
section.section.home-section-usps .home-section-usp-item .title{ font-size: 18px; font-weight: 600; text-transform: uppercase; letter-spacing: 1px; color: rgba(var(--black-rgb) / 80%); }

section.section.home-section-applications .home-section-applications-img{ width: 100%; height: 30vh; min-height: 300px; max-height: 450px; background-position: center center; background-size: cover; box-shadow: 6px 6px 9px rgba(var(--black-rgb) / 20%); border-radius: 4px; box-shadow: 8px 8px 6px rgba(var(--black-rgb) / 20%); border: 10px solid rgba(var(--black-rgb) / 60%); max-width: 600px; margin: auto; }

section.section.home-section-join-hands{ font-size: 10px; background-position: top center; background-size: cover; }
section.section.home-section-join-hands .home-section-join-hands-content{ max-width: 660px; margin: auto; }
section.section.home-section-join-hands .title{  color: rgba(var(--black-rgb) / 100%); mix-blend-mode: multiply; font-size: 2.6em; font-weight: 800; text-shadow: 1px 1px 1px rgba(var(--black-rgb) / 40%); }
section.section.home-section-join-hands .desc{ color: rgba(var(--black-rgb) / 60%); font-size: 1.2em; }


@media (min-width: 576px) {
    section.section.home-section-join-hands{ font-size: 11px; }
}
@media (min-width: 768px) {
    section.section.home-section-join-hands{ font-size: 13px; }
}
@media (min-width: 992px) {
    section.section.home-section-join-hands{ font-size: 14px; }
}
@media (min-width: 1200px) {
    section.section.home-section-join-hands{ font-size: 15px; }
}
@media (min-width: 1400px) {
}