/* footer.footer{  } */
footer.footer .footer-social ul li{ width: 12vw; min-width: 60px; max-width: 180px; }
footer.footer .footer-social ul li svg{ height: 20px; fill: rgba(var(--black-rgb) / 60%); }
footer.footer .footer-social ul li:hover svg{ fill: rgba(var(--black-rgb) / 100%); }

footer.footer .footer-menu .logo{ height: 10vh; min-height: 90px; max-height: 150px; }
footer.footer .footer-menu .logo:first-of-type{ border-bottom: 1px solid rgba(var(--black-rgb) / 12%); }
footer.footer .footer-menu .logo img{ height: 100%; width: auto; }
footer.footer .footer-menu .logo img:first-of-type{ border-right: 1px solid rgba(var(--black-rgb) / 12%); }

footer.footer .footer-menu .menu .footer-menu-nav .nav .nav-item{ line-height: 1; }
footer.footer .footer-menu .menu .footer-menu-nav .nav .nav-item a{ color: rgba(var(--black-rgb) / 65%); line-height: 25px; }
footer.footer .footer-menu .menu .footer-menu-nav .nav .nav-item a:hover{ color: rgba(var(--yellow-rgb) / 100%); }

footer.footer .footer-copyrights .footer-copyrights-content{ font-size: 12px; color: rgba(var(--black-rgb) / 45%); }



@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
    footer.footer .footer-social ul li svg{ height: 22px; }
    footer.footer .footer-menu .logo:first-of-type{ border-bottom: none; }
    footer.footer .footer-menu .logo img:first-of-type{ border-right: none; }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}