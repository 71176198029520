/**** THEME SCHEMA START ****/
:root{
    --green-rgb: 114 198 58;
    --blue-rgb: 243 112 33;
    --yellow-rgb: 243 112 33;
    --red-rgb: 243 112 33;
    --black-rgb: 45 58 75;
    --white-rgb: 255 255 255;
    --sm: 576px;
    --md: 768px;
    --lg: 992px;
    --xl: 1200px;
    --xxl: 1400px;
}
/**** THEME SCHEMA END ****/

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&display=swap');
body{
    font-family: 'Lato', sans-serif !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    color: rgba(var(--black-rgb) / 75%) !important;
}
.transition-effect{ -webkit-transition: all 0.3s; -moz-transition: all 0.3s; transition: all 0.3s; }
.color-green{ color: rgba(var(--green-rgb) / 100%); }