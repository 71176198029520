@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
/* GLOBAL CSS */
:root{
    --vs-black-rgb: 45 58 75;
    --vs-green-rgb: 114 198 58;
    --vs-yellow-rgb: 243 112 33;
}
::-webkit-input-placeholder {font-size:14px !important;}
::-moz-placeholder { font-size:14px !important;}
:-ms-input-placeholder { font-size:14px !important;}
:-moz-placeholder {font-size:14px !important;}
.vnext-solutions-wrapper{font-family: "Raleway", sans-serif !important;}
.vnext-solutions-wrapper p, .vnext-solutions-wrapper li{color: #707070;font-weight: 400;letter-spacing: .5px;}
/* GLOBAL CSS */
/* SLIDER */
.vs-slider{ background-repeat:repeat-x;background-size: cover;-webkit-animation: slider-bg-effect-animate 19000s linear infinite; animation: slider-bg-effect-animate 19000s linear infinite; z-index: 0; min-height: 600px;box-shadow: 0 .1rem 1rem rgba(0,0,0,.175)!important;}
.vs-slider::after{position:absolute;content:'';height:100%;width:100%;top:0;left:0;background:#FFF;background:#FFF;opacity:.85;z-index:-1}
@-webkit-keyframes slider-bg-effect-animate{from{background-position:0 0}to{background-position:90000% 0}}
@keyframes slider-bg-effect-animate{from{background-position:0 0}to{background-position:90000% 0}}
.vs-slider .logo img{width: 150px;}
.vs-slider h1{font-weight: 600;color: #37363C;}
.vs-slider .buttons .btn{background-color: rgba(0, 0, 0, 0.50) !important;}
.vs-slider button a{color: #fff;text-decoration: none;}
.vs-slider .enquiryForm span.text-danger{text-align: left !important;font-weight: 500;}
.vs-slider .enquiryForm .btn{background-color: #222 !important;}
.vs-slider .enquiryForm label{font-weight: 400;font-size: 14px;}
.vs-slider .enquiryForm .form-control{padding: 0 10px;background: #fff;color: #666;border: 1px solid #e5e5e5 !important;transition: .2s ease-in-out;transition-property: color, background-color, border;height: 40px;padding-left: 40px !important;}
.vs-slider .enquiryForm .icon svg{position: absolute;top: 50%;width: 18px;display: inline-flex;justify-content: center;align-items: center;fill: #999;left: 3%;transform: translate(0px, -50%);}
.vs-slider .enquiryForm .form-select{border: 1px solid #e5e5e5 !important;color: #666;font-size: 14px !important;}
.vs-slider .enquiryForm legend{color: #666;font-size: 20px;font-weight: 400;}
.s01_next{position: absolute;bottom: 10px;left: 50%;transform: translateX(-50%);}
.s01_next{background: rgba(0, 0, 0, 0.5);border-radius: 50%;width: 35px;height: 35px;line-height: 35px;text-align: center;cursor: pointer;}
.s01_next svg{fill:#fff;width: 13px;}
/* SLIDER */
/* FORM */
.vs-form{transform: translate(-10%, -50%);}
/* ABOUT */
.vs-about .content h1{font-size:45px;font-weight:200;color:rgba(var(--vs-black-rgb) / 100%);text-transform: uppercase;}
.vs-about .content h1 span{font-size:45px;font-weight:900;color:#25ce9e;}
.vs-about .content h1 svg{fill: rgba(var(--vs-black-rgb) / 100%);width: 40px;}
/* ABOUT */
/* PRODUCTS */
.vs-products{background-color:#F1F3F8;}
.vs-products .heading h2{font-size:45px;font-weight:200;color:rgba(var(--vs-black-rgb) / 100%);text-transform: uppercase;}
.vs-products .heading h2 span{font-size:45px;font-weight:900; color:rgba(var(--vs-yellow-rgb) / 100%);}
.vs-products .heading h3{font-size:35px;font-weight:300; color: #464646;text-transform: capitalize;}
.vs-products .heading h4{font-weight: 800;color: rgba(var(--vs-black-rgb) / 100%);font-size: 32px;}
.vs-products .content h4{color:rgba(var(--vs-black-rgb) / 100%);}
.nav-tabs .nav-link{color:rgba(var(--vs-black-rgb) / 100%); }
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{border-color: transparent;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{background-color: transparent;border-bottom: 1px solid #FF9800 !important;border-color: transparent;opacity: inherit !important;}
.nav-tabs .nav-link{position: relative;opacity: 0.7;padding: 8px 30px;}
.nav-link:focus-visible{outline: none !important;box-shadow:none !important;}
/* PRODUCTS */
/* ADVANTAGES */
.vs-advantages .heading h2{font-size:45px;font-weight:200;color:rgba(var(--vs-black-rgb) / 100%);text-transform: capitalize;}
.vs-advantages .heading h3{font-size:45px;font-weight:900;color:rgba(var(--vs-yellow-rgb) / 100%);text-transform: capitalize;}
.vs-advantages .box{box-shadow: 0 5px 15px rgba(0, 0, 0, .08);}
.vs-advantages .box:hover{box-shadow: 0 14px 25px rgba(0, 0, 0, .16);cursor: pointer;}
.vs-advantages .content img{ width: 100px;}
.vs-advantages .content p{color:#333;}
/* ADVANTAGES */
/* CERTIFICATIONS */
.vs-certifications{background-color:#f8f8f8;}
.vs-certifications h2{font-size:45px;font-weight:900;color:rgba(var(--vs-yellow-rgb) / 100%);text-transform: capitalize;}
.vs-certifications h2 span{font-size:45px;font-weight: 200;color:rgba(var(--vs-black-rgb) / 100%);text-transform: capitalize;}
.vs-certifications .content p{font-weight: 600;color: #464646 !important;text-transform: uppercase;}
.vs-certifications .content .box:hover{box-shadow:0 14px 25px rgba(0,0,0,.16) !important;cursor: pointer;}
/* CERTIFICATIONS */
/* FOOTER */
.vnext-solutions-wrapper footer{background-color: #222;}
.vnext-solutions-wrapper footer a{color:#FF9800;font-weight: 600;text-decoration: none; }
/* FOOTER */
/** RESPONSIVE **/
@media only screen and (max-width: 767px) {
    .vnext-solutions-wrapper h1 {font-size: 25px !important;}
    .vnext-solutions-wrapper h1 span{font-size: 25px !important;}
    .vnext-solutions-wrapper h2 {font-size: 25px !important;}
    .vnext-solutions-wrapper h2 span{font-size: 25px !important;}
    .vnext-solutions-wrapper h3{font-size: 25px !important;}
    .vs-about .content h1 svg {width: 15px;}
    .vs-slider{height: 100% !important; animation: inherit !important; padding-bottom:50px !important;}
    .vs-slider .enquiryForm{width: 100% !important;}
    .vs-slider .logo img {width: 100px;}
    .border-none{ border:none !important;}
    .s01_next{bottom: 0px !important;}
    .vs-form{position: inherit !important;transform: inherit !important;}
    .vh-auto{height: auto !important;}
}
@media screen and (min-device-width: 768px) and (max-device-width: 992px) { 
    .vs-slider{height: 100% !important; padding-bottom:50px !important;}
    .vs-form{position: inherit !important;transform: inherit !important; max-width: 400px; margin: auto; display: block;}
    .vh-auto{height: auto !important;}
}
/** RESPONSIVE **/