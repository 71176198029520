
.applications-navbar h6{ color: rgba(var(--yellow-rgb) / 100%); letter-spacing: 0.5px; }
.applications-navbar ul li{ line-height: 40px; }
.applications-navbar ul li .nav-link:hover,
.applications-navbar ul li .nav-link:focus{ color: rgba(var(--yellow-rgb) / 100%); }
.image-gallery-slide .image-gallery-image{ object-fit: inherit !important; }
.image-gallery-slide .image-gallery-image{ border-radius:5px; }
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{ width: 30px; }
.image-gallery-thumbnail.active, 
.image-gallery-thumbnail:focus, 
.image-gallery-thumbnail:hover { outline: none; border: 4px solid rgba(var(--yellow-rgb) / 100%); }
.image-gallery-icon:hover { color: rgba(var(--yellow-rgb) / 100%); }
.application-details .title h4{ color: rgba(var(--black-rgb) / 100%); }
