section.page-not-found{ font-size: 10px;}
section.page-not-found img{ width:100%; max-width:450px;}
section.page-not-found .content{ padding: 10em 0em; max-width: 600px; margin: auto; }
section.page-not-found .content .title{ font-size: 4.4em; font-weight: 800; line-height: 1; margin-bottom: 15px; color: rgba(var(--black-rgb) / 70%); text-shadow: 1px 1px 2px rgba(var(--black-rgb) / 30%); }
section.page-not-found .content .desc{ color: rgba(var(--black-rgb) / 50%); font-size: 1.4em; line-height: 1.4; text-shadow: .5px .5px 1px rgba(var(--black-rgb) / 20%); }
section.page-not-found .content .btn{ background-color: rgba(var(--yellow-rgb) / 100%); color: rgba(var(--white-rgb) / 100%); padding: 1.1em 2.4em; line-height: 1; text-transform: uppercase; letter-spacing: 1px; font-size: 1.2em; background-blend-mode: difference; border-radius: 4px; }

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
    section.page-not-found{ font-size: 11px; }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}