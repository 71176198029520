
.form-control, .form-select{
    border-color: #ccc !important;
}
.form-control:focus, .form-select:focus, .btn-close:focus{
    box-shadow: none !important;
    outline: transparent !important;
    border-color: rgb(var(--yellow-rgb)) !important;
}
.form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after{
    background-color: transparent !important;
}
form .text-danger{
    font-size: 13px;
}
input:-internal-autofill-selected {
    background-color: transparent !important;
}
.btn{
    background-color: rgb(var(--yellow-rgb)) !important;
    letter-spacing: 1px;
    color: #fff !important;
}
.list-group-item-action:active{
    background-color: transparent !important;
}
.list-group-item.active svg{
    fill:rgb(var(--yellow-rgb)) !important;
}
.list-group-item{
    border:none !important;
}
.list-group-item.active{
    background-color: transparent !important;
    color: rgb(var(--yellow-rgb)) !important;
}
.successForm svg{
    fill: rgb(var(--yellow-rgb));
    width: 65px;
}

.successForm .goToHomeBtn{
    background-color: rgb(var(--yellow-rgb));
    display: inline-block;
}
.errorForm svg{
    fill: red;
    width: 65px;
}
.errorForm h2{
    color: red;  
 }
.errorForm .goToHomeBtn{
    background-color: red;
    display: inline-block;
}
@media screen and (min-device-width: 992px) and (max-device-width: 1024px) { 
    /* STYLES HERE */
    .mx-lg-auto{margin:auto !important}
    .d-lg-block{display: block !important}
}