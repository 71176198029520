


section.section{ padding: 80px 0; }
section.section.dark{ background-color: rgba(var(--black-rgb) / 95%); }
section.section.light{ background-color: rgba(var(--white-rgb) / 95%); }
section.section.dark.bg-img{ background-attachment: fixed; background-position: center center; background-size: cover; }
section.section .section-heading{ font-size: 9px; }
section.section .section-heading .section-heading-items{ width: fit-content; margin: auto; }
section.section .section-heading .section-heading-items h2{ font-weight: 600; line-height: 1; margin-bottom: 0.1em; font-size: 3.8em; }
section.section.dark .section-heading .section-heading-items h2{ color: rgba(var(--white-rgb) / 100%); }
section.section.light .section-heading .section-heading-items h2{ color: rgba(var(--black-rgb) / 100%); }
section.section .section-heading .section-heading-items p{ margin: 0; letter-spacing: .5px; color: rgba(var(--black-rgb) / 65%); font-size: 1.6em; }
section.section.dark .section-heading .section-heading-items p{ color: rgba(var(--white-rgb) / 65%); }
section.section.light .section-heading .section-heading-items p{ color: rgba(var(--black-rgb) / 65%); }
section.section .section-heading .section-heading-items .border-effect{ height: 3.5px; min-width: 100px; max-width: 500px; }
section.section .section-heading .section-heading-items .border-effect span{ background-color: rgb(var(--yellow-rgb) / 100%); }
section.section .section-heading .section-heading-items .border-effect span:nth-of-type(2),
section.section .section-heading .section-heading-items .border-effect span:nth-of-type(4){ background-color: rgb(var(--yellow-rgb) / 75%); }
section.section .section-heading .section-heading-items .border-effect span:first-of-type,
section.section .section-heading .section-heading-items .border-effect span:last-of-type{ background-color: rgb(var(--yellow-rgb) / 50%); }
section.section .section-heading .section-heading-items .w-8{ width: 8%; }
section.section .section-heading .section-heading-items .w-16{ width: 16%; }
section.section .section-heading .section-heading-items .w-52{ width: 52%; }

section.section .section-body{ font-size: 10px; }
section.section .section-body p{ font-size: 1.6em; }
section.section.dark .section-body p{ color: rgba(var(--white-rgb) / 70%); }
section.section.light .section-body p{ color: rgba(var(--black-rgb) / 70%); }
section.section .section-body .btn{ padding: 12px 28px; line-height: 1; letter-spacing: .5px; border-radius: 4px; box-shadow: 1px 2px 3px rgba(var(--black-rgb) / 50%); }
section.section.light .section-body .btn{ color: rgba(var(--white-rgb) / 80%); background: rgba(var(--black-rgb) / 100%); }
section.section.dark .section-body .btn{ color: rgba(var(--black-rgb) / 100%); background: rgba(var(--white-rgb) / 100%); }
section.section .section-body .btn.yellow{ color: rgba(var(--white-rgb) / 80%); background: rgba(var(--yellow-rgb) / 100%); }


@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
    section.section .section-heading{ font-size: 10px; }
    section.section .section-body{ font-size: 10.5px; }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
    section.section .section-heading{ font-size: 11px; }
    section.section .section-body{ font-size: 11px; }
}