.solutions-list .image img{
    width: 100%;
    max-height: 280px;
    min-height: 280px;
}
.solutions-list .title {
    font-size: 20px;
    letter-spacing: 1px;
    color: rgba(var(--yellow-rgb) / 100%);
}
.solutions-list .view-more{
    background-color: rgba(var(--white-rgb) / 80%);
    cursor: pointer;
    opacity: 0;
    transition: opacity .4s ease;
}
.solutions-list .view-more:hover{ opacity: 1; }
.solutions-list .view-more .icon{ width: 45px; height: 45px; background-color: rgba(var(--yellow-rgb) / 90%); }
.solutions-list .view-more svg{ width: 15px; fill: rgba(var(--white-rgb) / 100%); }

.solutions-details{
background-color: rgba(var(--black-rgb) / 5%); 
}
.solutions-details .title{
    letter-spacing: 0.5px;
}
.solutions-details ul{
    list-style: none;
    margin:0;
    padding:0;
    line-height: 40px;
}   
.solutions-details .content svg{
    fill: rgba(var(--yellow-rgb) / 100%);
}
.solutions-details .download-brochure a{
    background-color: rgba(var(--yellow-rgb) / 100%);
}