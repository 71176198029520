header{position: sticky; top:0; z-index: 9;}
header .header-top{ background-color: rgba(var(--black-rgb) / 3%); font-size: 14px; }
header .header-top .nav .nav-item a{ color: rgba(var(--black-rgb) / 60%); }
header .header-top .nav .nav-item a:hover{ color: rgba(var(--yellow-rgb) / 100%); }
.dropdown-item.active, .dropdown-item:active{background-color: rgba(var(--yellow-rgb)) !important;}

header .header-bottom.navbar { background-color: rgba(var(--white-rgb) / 100%); }
header .header-bottom.navbar .navbar-brand img{ width: 100%; min-width: 50px; max-width: 70px; }
header .header-bottom.navbar .navbar-nav .nav-item .nav-link{ padding: 10px 12px; font-size: 14px; }
header .header-bottom.navbar .navbar-nav .dropdown-menu .dropdown-item{ padding: 8px 12px; font-size: 14px; }
header .header-bottom.navbar .navbar-nav .nav-item .nav-link:hover{ color: rgba(var(--yellow-rgb) / 100%); }
header .header-bottom.navbar .navbar-nav .nav-item .nav-link.active{ font-weight: bold; }
header .header-bottom.navbar .theme-button{ background-color: rgba(var(--black-rgb) / 100%); color: rgba(var(--white-rgb) / 100%); line-height: 1; padding: 12px 15px; }
header .header-bottom.navbar .theme-button:hover{ background-color: rgba(var(--yellow-rgb) / 100%); }
