@import "~react-image-gallery/styles/css/image-gallery.css";
/* global css */
body{
    overflow-y: scroll !important;
    padding-right: 0px !important;
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #f36f21; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    background-color:transparent;
  }
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    background: rgb(255 255 255 / 99%);
  }
  /* global css */

  /* testimonial */
  .testimonial-box .icon{
    fill:#f36f21;
    width: 30px;
    height: 30px;
    display: inline-flex;
  }
  .testimonial-box .author{
    color:#f36f21;
    font-style: italic;
  }
  .testimonials{
    align-items: baseline;
  }
  /* testimonial */

  /*accessories*/
  .accessories .heading p{
    color: rgba(var(--white-rgb) / 70%);
  }
  .accessories .tgrid-system-item {
    border:1px solid rgba(var(--white-rgb) / 50%);
  }
  .accessories .title h5{
    color:#f36f21;
  }
  .accessories img{
    height: 200px;
  }
  /*accessories*/
  /* certificates*/
  .certificates .carousel-control-prev-icon,
  .certificates .carousel-control-next-icon {
    background-color:rgba(var(--yellow-rgb) / 100%); /* Set the color of the arrow icons */
    border:5px solid rgba(var(--yellow-rgb) / 100%);
  }

  .certificates .carousel-control-prev-icon,
  .certificates .carousel-control-next-icon {
    width: 25px; /* Set the width of the arrow icons */
    height: 25px; /* Set the height of the arrow icons */
    background-color: rgba(var(--yellow-rgb) /100%); /* Set the color of the arrow icons */
    border-radius:50px;
  }
  .certificates .carousel-control-next, .certificates .carousel-control-prev{
      opacity: 1;
  }
  @media only screen and (max-width: 767px){
    .certificates .carousel.w-50{
      width: 100% !important;
    }
    .successForm>div{
      width: 100% !important;
    }
  }
  /* certificates*/