.coverages-list .image{
    max-height: 260px;
    min-height: 260px;
}
.coverages-list .title {
    color: rgba(var(--black-rgb) / 100%);
}
.coverages-list .view-more{
    background-color: rgba(var(--white-rgb) / 80%);
    cursor: pointer;
    opacity: 0;
    transition: opacity .4s ease;
}
.coverages-list .view-more:hover{ opacity: 1; }
.coverages-list .view-more .icon{ width: 45px; height: 45px; background-color: rgba(var(--yellow-rgb) / 90%); }
.coverages-list .view-more svg{ width: 15px; fill: rgba(var(--white-rgb) / 100%); }

.coverage-details .title{
    color: rgba(var(--black-rgb) / 100%);
}
.coverage-details .back{
    cursor: pointer;
}
.coverage-details .back svg, 
.coverage-details .date svg{
    width: 24px;
    height: 24px;
    fill: rgba(var(--black-rgb) / 100%);
}
.pagination .page-link{
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    padding: 0px;
    color: rgba(var(--yellow-rgb) / 100%);

}
.active>.page-link, .page-link.active, .page-link:focus {
    background-color: rgba(var(--yellow-rgb) / 90%);
    border-color: rgba(var(--yellow-rgb) / 90%);
    color: rgba(var(--white-rgb) / 90%);
    box-shadow: none !important;
}
.page-item:first-child .page-link, .page-item:last-child .page-link{
    width: inherit !important;
    height: inherit !important;
    margin: 0px 15px !important;
    color: rgba(var(--black-rgb) / 90%);
}

